// External packages
import * as React from "react"
import { Box, Flex, Paragraph, Text } from "theme-ui"

// Hooks
import { useZendesk } from "../../src/hooks/v2/useZendesk"

// Components
import Layout from "../components/layouts"
import SEO from "../components/seo"
import { Button } from "../../src/components/v2/Button"
import { Link } from "../../src/components/v2/Link"

const NotFoundPage = () => {
  const { isActivated, setIsActivated, ZendeskWidget } = useZendesk()

  return (
    <Layout almostWhite is404Page>
      <SEO title="Not found" />
      <Flex
        sx={{
          height: [null, "100%"],
          width: "100%",
          maxWidth: "883px",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          textAlign: "center",
          gap: 20,
          fontSize: "sm",
          paddingBlockStart: [16, 30],
          paddingBlockEnd: 10,
          marginInline: "auto",
        }}
      >
        <Box>
          <Paragraph sx={{ marginBlockEnd: 10 }}>
            We apologise. This page is not available.
          </Paragraph>
          <Button
            href="/all-products"
            variant="ghost"
            sx={{ maxWidth: "216px", width: "100%" }}
          >
            Explore all products
          </Button>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Paragraph
            sx={{ textAlign: "left", paddingInline: 4, marginBlockEnd: 4 }}
          >
            Explore:
          </Paragraph>
          <Flex
            sx={{
              width: "100%",
              textAlign: "left",
              gap: 4,
              flexShrink: 0,
              overflowX: ["scroll", "scroll", "unset"],
              scrollbarWidth: "none",
              paddingInline: 4,

              "::-webkit-scrollbar": { display: "none" },
              img: { display: "block" },
            }}
          >
            <Link
              to="/category/bedroom"
              sx={{ display: "block", width: "273px", flexShrink: 0 }}
            >
              <Box>
                <img
                  width="273"
                  height="342"
                  decoding="async"
                  src="https://images.ctfassets.net/4g6al16haqoj/3yu5Z3CwNPwQJxdSRCkPO3/0af27c62d2e125a9d78d8a0188bc8ef5/TEKLA-Category-slider-percale.jpg?w=546&amp;h=684"
                  alt="TEKLA-Category-slider-percale"
                />
              </Box>
              <Text sx={{ display: "block", marginBlockStart: 2 }}>
                Bedroom
              </Text>
            </Link>
            <Link
              to="/category/sleepwear"
              sx={{ display: "block", width: "273px", flexShrink: 0 }}
            >
              <img
                width="273"
                height="342"
                decoding="async"
                src="https://images.ctfassets.net/4g6al16haqoj/6WizPGpyrAKwb0kixLNpDE/46c423043f1ba230a0f52bfa24139ae7/OlaRindal-Spring-Sleepwear10.jpg?w=546&amp;h=684"
                alt="TEKLA-Category-slider-percale"
              />
              <Text sx={{ display: "block", marginBlockStart: 2 }}>
                Sleepwear
              </Text>
            </Link>
            <Link
              to="/category/bathroom"
              sx={{ display: "block", width: "273px", flexShrink: 0 }}
            >
              <img
                width="273"
                height="342"
                decoding="async"
                src="https://images.ctfassets.net/4g6al16haqoj/41vad1yzKOpJVd7AKQ5K1I/b449689616d62e133906c812a95cc4d6/SHOT01_0221_copy.jpg?w=546&amp;h=684"
                alt="TEKLA-Category-slider-percale"
              />
              <Text sx={{ display: "block", marginBlockStart: 2 }}>
                Bathroom
              </Text>
            </Link>
          </Flex>
        </Box>
        <Box>
          <Text>Need help?</Text>{" "}
          <Link variant="underline" onClick={() => setIsActivated(true)}>
            Contact us
          </Link>
        </Box>
      </Flex>
      {isActivated && <ZendeskWidget />}
    </Layout>
  )
}

export default NotFoundPage
